<template>
  <div class="container treatment-container">
    <div class="row">
      <div class="col-12">
        <router-view class="mt-3"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "treatments",
};
</script>
